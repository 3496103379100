.table_body {
  max-height: 70vh;
  min-height: 70vh;
  overflow: scroll;
}

.table_body::-webkit-scrollbar {
  display: none;
}

.table {
  max-height: 80vh;
  margin: auto;
}

.table thead tr th {
  text-align: center;
}
