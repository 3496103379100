.floating-label-select {
  position: relative;
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.2s ease all;
  pointer-events: none;
  z-index: 2;
}

.floating-label-select.focused .floating-label,
.floating-label-select .react-select__control--is-focused .floating-label,
.floating-label-select
  .react-select__value-container--has-value
  .floating-label {
  top: 10px;
  left: 10px;
  /* background-color: hsl(var(--nextui-default-200) / var(--nextui-default-200-opacity, var(--tw-bg-opacity))); */
  font-weight: normal;
  font-size: x-small;
}
