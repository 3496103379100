.elements td input {
  padding: 10px 5px 10px 2px;
  font-size: smaller;
  font-weight: bold;
  text-align: center;
}

.table_body {
  /* height: 90vh;
    min-height: 80vh; */

  overflow: scroll;
}

.table {
  max-height: 84vh;
  width: 97vw;
  margin: auto;
  margin-top: 20px;
}

.table thead tr th {
  text-align: center;
}
